import $vuetify from 'vuetify/es5/locale/en'

export default {
  generic: {
    ok: 'Ok',
    no: 'No',
    yes: 'Yes',
    save: 'Save',
    saved: 'Saved',
    saving: 'Saving',
    saveNow: 'Save now',
    loading: 'Loading',
    success: 'Success',
    error: 'Error',
    errorMsg: 'Something went wrong. Please try again...',
    cancel: 'Cancel',
    search: 'Search',
    searchNoResults: "No search resuls found for '{search}'",
    update: 'Update',
    remove: 'Remove',
    submit: 'Submit',
    gotIt: 'Got it!',
    apply: 'Apply',
    menu: 'Menu',
    back: 'Back',
    more: 'More',
    none: 'None',
    close: 'Close',
    route: 'Route',
    routes: 'Routes',
    thisRoute: 'this route',
    climbing: 'Climbing',
    rope: 'Rope',
    boulder: 'Boulder',
    boulders: 'Boulders',
    thisBoulder: 'this boulder',
    bouldering: 'Bouldering',
    settings: 'Settings',
    readMore: 'Read more',
    details: 'Details',
    today: 'Today',
    tomorrow: 'Tomorrow',
    total: 'Total',
    copied: 'Copied',
    comingSoon: 'Coming soon',
    linkCopiedMsg: 'Link was copied to clipboard',
    noData: 'No data available',
    refresh: 'Refresh',
    offlineMsg: 'You are offline.',
    offlineMsgSave: 'Changes will be saved when you get back online.',
    offlineMsgNotSaved: 'Your changes will not be saved.',
    badConnection: 'Bad connection',
    pagination: {
      frst: 'First page',
      prev: 'Previous page',
      next: 'Next page',
      last: 'Last page',
    },
  },
  inputRules: {
    required: 'Required',
    minLength: 'Should be at least {length} characters',
    maxLength: 'Should be at most {length} characters',
    minVal: 'Should be at least {minVal}',
    maxVal: 'Should be at most {maxVal}',
    emailInvalid: 'Invalid e-mail',
    shouldMatchPwd: 'Should match password',
    dateInvalid: 'Invalid date',
    timeInvalid: 'Invalid time',
  },
  sidenav: {
    profilePic: 'Profile picture',
    withoutName: 'User without name',
    atGym: 'At {gymName}',
    profile: 'Profile',
    myProfile: 'My profile',
    switchGym: 'Switch Gym',
    selectGym: 'Select Gym',
    signOut: 'Sign out',
    signIn: 'Sign in',
    closeApp: 'Close app',
    routes: 'Routes',
    boulders: 'Boulders',
    dashboard: 'Dashboard',
    competitions: 'Competitions',
    myGym: 'My Gym',
    reservations: 'Reservations',
    inbox: 'Inbox',
    editRoutes: 'Edit Routes',
    editBoulders: 'Edit Boulders',
    editCompetitions: 'Edit Competitions',
    communications: 'Communications',
    statistics: 'Statistics',
    gym: 'Gym',
    monitoring: 'Monitoring',
    help: 'Help',
    aboutUs: 'About us',
    contact: 'Contact',
    sendFeedback: 'Send feedback',
    toc: 'Terms of Service',
  },
  auth: {
    loginTitle: 'Login | TopLogger',
    loginDescription: 'Login to the toplogger web app or create your account.',
    resetPwdTitle: 'Reset password | TopLogger',
    resetPwdDescription: 'Reset your password for TopLogger',
    unauthorized: 'Unauthorized',
    login: 'Log in',
    signIn: 'Sign in',
    guestAccount: 'Guest account',
    resetPwd: 'Reset password',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    newsletters: 'Subscribe to newsletter',
    forgotBtn: 'Forgot password?',
    forgotHeader: 'Forgot your password?',
    forgotExpl: "No worries: just enter your email address below and we'll send you an instruction email for recovery.",
    remember: 'Remember again?',
    signUp: 'Sign up',
    google: 'Sign in with Google',
    facebook: 'Sign in with Facebook',
    createAccount: 'Create an account',
    createsAccount: 'Automatically creates an account linked to your email',
    haveAccount: 'Already have account?',
    msgLoggedIn: 'Logged in',
    msgLoggedOut: 'Logged out',
    msgSentConfirm: 'An email will be sent to you for confirmation.',
    msgSentReset: 'An email will be sent to you to reset your password.',
    msgPwdReset: 'Success! Your password has been updated.',
    msgSentConfirmNew: 'An email will be sent to your new address for confirmation.',
    msgPwdUpdated: 'Your password has been updated.',
    loginToSaveProgress: 'You need to be logged in to get your progress saved',
    actionRequiresLogin: 'This action requires you to be logged in.',
  },
  profile: {
    title: 'Your profile | TopLogger',
    description: 'Change your profile and customize your settings and defaults for TopLogger',
    info: {
      changePicture: 'Change profile picture',
      zoomToCrop: 'Zoom and drag to crop',
      upload: 'Upload',
      socialMedia: 'Social media',
      changeFile: 'Change file',
      chooseFile: 'Choose file',
      firstName: 'First name',
      lastName: 'Last name',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      country: 'Country',
      city: 'City',
      dateBirth: 'Date of birth',
      hideDateBirth: 'Hide date from other users',
      newsletters: 'Receive newsletter',
      anonymous: 'Anonymous',
      length: 'Height (cm)',
      weight: 'Weight (kg)',
      email: 'Email',
      changeMail: 'Change email',
      enterNewMail: 'Please enter your new email address',
      newEmail: 'New email',
    },
    password: {
      changePwd: 'Change Password',
      newPwd: 'New Password',
      confirmNewPwd: 'Confirm new password',
    },
    preferences: {
      preferences: 'Preferences',
      language: 'Language',
      anonymousSuffix: 'Hidden in gym rankings',
      genderSetToast: "To appear in the rankings your gender was set to 'Male'.",
      anonymousSetToast: 'You are shown as anonymous: rankings require a gender.',
    },
    notifications: {
      notifications: 'Notifications',
      channels: 'Channels',
      push: 'Push',
      newRoutes: 'New routes',
      newBoulders: 'New boulders',
      news: 'News',
      competitions: 'Competitions',
      restrictRange: 'Restrict grade range',
      onlyRoutesBetween: 'Only notify me for new routes between',
      onlybouldersBetween: 'Only notify me for new boulders between',
      selectGym: 'Select a gym for notifications',
      gymsSelected: '{number} selected',
      noGuarantee:
        'Note: the gym admin decides which updates are sent, so there is no guarantee you will always be notified.',
    },
  },
  gyms: {
    title: 'Available gyms | TopLogger',
    description: 'A list of all gyms available in TopLogger. Select your gym to log your routes and boulders.',
    gyms: 'Gyms',
    visitedGyms: 'Visited gyms',
    visitedGymsSubhead: 'Gyms where you have logged ascents in the past six months',
    gymsNearby: 'Gyms nearby',
    selectGym: 'Select your current gym',
    goToGym: 'Go to gym',
    gymLogo: 'Gym logo',
    commingSoon: 'Coming soon...',
    noSwitching: 'To switch gyms, download the free app on your device.',
    notAllowed: 'Sorry! Not allowed...',
  },
  climbs: {
    title: '{routesBoulders} currently at {gymName} | TopLogger',
    descriptionMap: 'An interactive map showing all the {routesBoulders} currently available at {gymName}.',
    descriptionList: 'A list of all the {routesBoulders} currently available at {gymName}.',
    listView: 'List view',
    mapView: 'Map view',
    showList: 'Show list',
    viewMap: 'View map',
    new: 'New',
    guestMsg: 'This is a guest account.',
    groups: 'Groups',
    walls: 'Walls',
    filters: {
      filters: 'Filters',
      filterBtn: 'Filter climbs',
      activeMsg: 'Filters are active',
      grade: 'Grade',
      starRating: 'Star rating',
      colors: 'Colors',
      todo: 'To-do',
      almostRemoved: 'Almost removed',
      forChildren: 'Suitable for children',
      selectAll: 'Select all',
      selectAllFiltered: 'Select all filtered',
      selectAllInView: 'Select all in view',
      deselectAll: 'Deselect all',
      clearFilters: 'Clear filters',
    },
    group: {
      sharedWith: 'Shared group with:',
      hiddenWall: '1 wall hidden by filters',
      hiddenWalls: '{count} walls hidden by filters',
      hiddenGroup: '1 group hidden by filters',
      hiddenGroups: '{count} groups hidden by filters',
      hiddenRoute: '1 route hidden by filters',
      hiddenRoutes: '{count} routes hidden by filters',
      hiddenBoulder: '1 boulder hidden by filters',
      hiddenBoulders: '{count} boulders hidden by filters',
    },
    log: {
      done: 'Done',
      top: 'Top',
      flash: 'Flash',
      onsight: 'Onsight',
      rp: 'RP',
      fl: 'FL',
      os: 'OS',
      repeat: 'Repeat',
      check: 'Check',
      tickOff: 'Tick off',
      zones: 'Zones',
      zone: 'Zone',
      myOpinion: 'My grade opinion',
      tries: 'Tries',
      repeats: 'Repeats',
      uncheckMsg: 'This will remove your ascent from {timeAgo}.',
      removeAscend: 'Remove ascent',
      project: 'Project',
      getApp: {
        toastTitle: 'Not here',
        toastMsg: 'Go to toplogger.nu to get it on your own devices...',
        header: 'This app is locked',
        title: 'Go get it on your own device',
        explanation:
          'To be able to save your progress, go to toplogger.nu and download the <i>free</i> app or use the web-version.',
      },
      all: {
        areYouSure: 'Are you sure?',
        update: 'This will update ascents from {timeAgo}.',
        uncheck: 'This will remove one ascent per {routeBoulder}, including ascents from {timeAgo}.',
        updateAscends: 'Update ascents',
        removeAscends: 'Remove ascents',
        logRoutes: 'Log all {count} routes',
        logBoulders: 'Log all {count} boulders',
      },
      stats: {
        stats: 'Stats',
        statistics: 'Statistics',
        dateSet: 'Date set',
        dateRemoved: 'Date removed',
        votesTitle: 'Grade votes',
        votesEmpty: 'No grade votes yet...',
        votesTotal: '{votes} total',
        ratingTitle: 'Rating summary',
        ratingEmpty: 'No ratings yet...',
        toppedHeader: 'Topped by',
        toppedSubhead: 'Excluding anonymous users',
        notToppedBoulder: "This boulder hasn't been topped yet.",
        notToppedRoute: "This route hasn't been topped yet.",
      },
      renew: {
        renewedSoonBoulder: 'Renewed soon',
        renewedSoonRoute: 'Renewed soon',
        vote: 'Vote for renewal',
        unvote: 'Unvote for renewal',
        voted: 'Voted (anonymous)',
        votedMsg: 'Added a vote to get {thisRouteBoulder} renewed.',
        unvoted: 'Vote removed',
        unvotedMsg: 'Removed your vote to get {thisRouteBoulder} renewed.',
      },
      report: {
        report: 'Report',
        reportClimb: 'Report {thisRouteBoulder}',
        explanation: 'Something is wrong: inform the gym.',
        messageLabel: 'Message to gym',
        successMsg: 'A notification will be sent to the gym.',
      },
      help: {
        header: 'Logging explained',
        buttons: {
          header: 'Log buttons',
          subhead: 'Which button applies when?',
          topExpl: "You sent it (you've successfully climbed it to the top).",
          flashExpl: 'You sent it on your first try.',
          os: 'OS: Onsight',
          osExpl: 'Sent<sup>1</sup> it on your first try, without beta<sup>2</sup>.',
          fl: 'FL: Flash',
          flExpl: 'Sent<sup>1</sup> it on your first try, but with beta<sup>2</sup>.',
          rp: 'RP: RedPoint',
          rpExpl: 'Sent<sup>1</sup> it, but not on your first try.',
          elaboration:
            'RedPointing often consists of elaborating all the moves and working on the route for some time before sending it.',
          sup1: '<sup>1</sup>Climbed: Getting to the top without falling nor hanging from the rope.',
          sup2:
            "<sup>2</sup>Beta: Any knowledge about the route from others, either by being told or by seeing how it's climbed.",
        },
        stability: {
          header: 'Stability indicator',
          explanation: 'The dot below the grade indicates grade stability. It has three values:',
          unstableText: '<strong>Unstable</strong>: Not enough votes yet. This grade is still subject to change.',
          midStableText:
            '<strong>Probably stable</strong>: Some more votes are required, but the grade is stabilizing.',
          stableText: '<strong>Stable</strong>: Many users voted on the grade. It will probably stay as it is.',
        },
        questions: {
          QgradeChange: 'What happens to my score if the grade changes?',
          AgradeChange: "Don't worry: your score will be automatically re-calculated on the spot.",
        },
      },
    },
  },
  dashboard: {
    title: 'TopLogger dashboard for {gymName}',
    description: 'Gym rankings, new climbs, your personal stats and progress at {gymName}.',
    otherUserMsg: 'Showing stats of {userName}',
    viewClimbs: 'View {routesBoulders}',
    showMine: 'Show mine',
    showDemoGraph: 'Show demo graph',
    showingDemoGraph: 'Showing demo graph',
    notEnoughLogged: 'You have not logged for long enough to make a proper graph.',
    guestUser: 'Guest user',
    statsLoadErrorMsg: "Couldn't fetch user stats.",
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    link: 'Link',
    score: {
      subhead: 'Stats and progress',
      countHeader: '{routesBoulders} count',
      countHeaderExpl: 'Number of {routesBoulders} topped at {gymName}',
      gradeHeader: '{routeBoulder} grade',
      gradeHeaderExpl: 'Average of your top 10 ascents in the past two months topped at {gymName}',
      historyHeader: '{routeBoulder} grade history',
      historySubhead: 'Grades based on tops in <strong>all</strong> gyms.',
      countBarText: '{count} of {total}',
      gradeBarTooltip: '{percentLeft}% left to reach ',
      effective: 'Effective',
      bonus: 'Bonus',
      points: 'Points',
      average: 'Average',
      bonusExplHeader: 'About bonus points',
      bonusExplOS: '<b>Onsight</b> adds two french grades, equivalent to 33.33 points.',
      bonusExplFL: '<b>Flash</b> adds one french grade, equivalent to 16.66 points.',
    },
    ranking: {
      header: 'Rankings for {gymName}',
      noName: 'No name',
      setNameMsg: 'Please enter your name to appear in the rankings. You can still toggle anonymous there.',
      anonymousMsg: 'You are anonymous, so you will not show in the rankings.',
      countHeader: 'Count ranking',
      countSubhead: 'Most tops at {gymName}',
      gradeHeader: 'Grade ranking',
      gradeSubhead: 'On average of the top 10 ascents in the past two months',
      all: 'All',
      tooFewRoutesMsg0: "You haven't climbed any route yet.",
      tooFewRoutesMsg1: 'You only sent one route.',
      tooFewRoutesMsg: 'You only sent {count} routes.',
      tooFewBouldersMsg0: "You haven't climbed any boulder yet.",
      tooFewBouldersMsg1: 'You only climbed one boulder.',
      tooFewBouldersMsg: 'You only climbed {count} boulders.',
      logMoreMsg: 'Log {count} more for a proper top 10 average.',
      logRoutesBtn: 'Log routes',
      logBouldersBtn: 'Log boulders',
      tooFewLogsMsg: 'Based on {count} logs',
    },
    oldNew: {
      header: '{routeBoulder} updates',
      subhead: 'Some updates about {routesBoulders} in your gym',
      lastIn: 'Last in',
      newClimbs: 'New {routesBoulders}',
      firstOut: 'First out',
      oldClimbs: 'Almost removed {routesBoulders}',
      daysLeft: '{count} days left',
      nothing: 'Nothing...',
    },
  },
  competitions: {
    title: 'Competitions at {gymName} | TopLogger',
    description:
      'A list of all the competitions at {gymName}. This includes past competitions and their rankings as well as current competitions.',
    compTitle: '{competitionName} at {gymName} | TopLogger',
    compDescription: "Results for competition '{competitionName}' at {gymName}.",
    compClimbsTitle: "The {routesBoulders} for '{competitionName}' | TopLogger",
    compClimbsDescMap: 'An interactive map showing all the {routesBoulders} for {competitionName}.',
    compClimbsDescList: 'A list of all the {routesBoulders} for {competitionName}.',
    noCompetitionsMsg: 'There are currently no competitions at {gymName}...',
    notParticipatingInMsg: "Competitions you're still not participating in:",
    competitionResults: 'Competition results',
    competition: 'Competition',
    expiredComp: 'Expired competition',
    expiredComps: 'Expired competitions',
    expiredCompsSubhead: 'Check out the results',
    noMoreLogging: "You can't log for this competition anymore.",
    logSubhead: 'Shows tops since the start of {compName} {startDate}',
    expiredToastr: 'Expired competition',
    expiredToastrMsgJoin: "The deadline has already passed, you can't join anymore",
    expiredToastrMsgQuit: "The deadline has already passed, you can't quit anymore",
    participate: 'Participate',
    participated: 'You participated',
    participating: 'You are participating',
    subscribed: 'You have subscribed',
    logClimbs: 'Log sends',
    notStarted: 'This competition has not started yet',
    unranked: 'Unranked',
    approvingMsg: 'Awaiting approval from the gym.',
    approvingMsg2: 'But you can already start logging.',
    approvingToastr: 'Awaiting approval',
    approvingToastrMsg: 'Your request needs to be approved by the gym.',
    approvedToastrMsg: 'Ready to climb!',
    genderRequiredMsg: 'Please, specify gender to participate',
    ageRequiredMsg: 'Please, enter your age to participate',
    setGender: 'Set gender',
    setAge: 'Set age',
    unsubscribeTitle: 'Unsubscribe from {competitionName}',
    unsubscribeMsg: 'Are you sure you want to quit?',
    unsubscribe: 'Unsubscribe',
    unsubscribedMsg: "You are no longer participating in '{competitionName}'.",
    start: 'Start',
    deadline: 'Deadline',
    ranking: 'Ranking',
    toggleRanking: 'Toggle ranking',
    delayedScoresMsg: 'Scores for this competition are re-calculated every 30 seconds.',
    refresh: 'Refresh scores',
    switchPoule: 'Switch category',
    selectPoule: 'Select category',
    selectPouleExpl:
      'This competition works withs with grouped rankings. Select the category you want to parcitipate in. You can modify your selection later.',
    help: {
      btn: 'Help',
      header: 'About competitions',
      generalTitle: 'How it works',
      generalExpl:
        'A competition is a ranking based on tops logged exclusively between the start date/time and the deadline.',
      generalExpl2:
        'If you already topped a climb before the start of the competition, you will have to repeat it for it to count in the ranking. The number of points per ascent is listed above each competition.',
      poulesTitle: 'Grouped rankings',
      poulesExpl:
        "Competitions can have grouped rankings or 'Categories'. This means you participate in a sub-competition including climbers with about the same grade. Upon registration, you will be asked to enter your own (redpoint) grade, so honesty is appreciated.",
    },
    info: {
      header: 'Rules and regulations',
      subhead: 'Specific to {groupName}',
      rules: 'Rules',
      pricesHeader: 'Prizes to win',
      scoreSystem: 'Score system',
      points: {
        description: 'Ranking based on points. Each top earns points, optionally more if climbed in one go.',
        points: '{points} points',
        bonus: '{points} bonus points',
      },
      pointsByGrade: {
        description:
          'Ranking based on points. Each top earns points, optionally more if sent in one go. The number of points per send is linked to the grade of the route, according to the grade conversion table. If there are zones, each zone counts as a top.',
        button: 'Show conversion table',
        explanation: 'Points according to grade.',
        efforts:
          'Only your best {efforts} efforts are used to calculate your score. If there are zones, each zone counts as one effort.',
      },
      divBy: {
        description: 'Each top (and each zone) earns 1000 divided by the number of participants who climbed it.',
        bonusPercent: '{percentage}% bonus points',
      },
    },
    setGrade: {
      header: 'Set your participation grade',
      explanation:
        "This competition works with categories, where you are classified by climbing grade. Within your category you'll compete with climbers having a similar grade range.",
      explanation2:
        'Please set your current highest (redpoint) grade. This grade will be used to determine your category:',
      minGradeExplanation: 'You have shown to be able to climb at least ',
    },
  },
  gym: {
    title: '{gymName} - General information | TopLogger',
    description: 'Contact information, opening hours and map for {gymName}.',
    currentGym: 'Current gym:',
    contact: 'Contact',
    phone: 'Phone',
    parking: 'Parking',
    website: 'Website',
    gallery: 'Gallery',
    gallerySubhead: 'Some impressions of the gym',
    impression: 'Impressions',
    hours: 'Opening hours',
    closed: 'Closed',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    location: 'Location',
  },
  reservations: {
    title: 'Bookings for {gymName} | TopLogger',
    description: 'Find available time slots and make a reservation to visit {gymName}.',
    accountRequiredMsg: 'You need an account to be able to reserve a time slot',
    slotsHeader: 'Available time slots',
    selectArea: 'Select your area',
    spotsLeft: '{spots} spots left',
    dialogTitle: 'Book your time slot',
    notAvailableYet: 'Not available yet',
    available: 'Available',
    customerInformation: 'Customer information',
    associate: 'Partner',
    addAssociate: 'Add partner',
    removeAssociate: 'Remove partner',
    book: 'Book',
    myBookings: 'My bookings',
    expiredBookings: 'Expired bookings',
    lastMinuteBooking: 'This is a last-minute booking',
    lastMinuteBookingExpl: 'Last-minute bookings do not count towards restrictions in the number of bookings',
    fullyBooked: 'Fully booked',
    cancelBooking: 'Cancel booking',
    bookingNumber: 'Booking number',
    clientNumber: 'Client number',
    passwordHint: 'The password specific to this time slot',
    sendConfirmation: 'Send confirmation email',
    acceptContact: 'I allow the gym to contact me in case of changes in my reservation',
    checkinTime: 'Check-in time',
    checkout: 'Check out',
    checkOutTablet:
      'Enter the email used for your reservation to checkout. Any partners from your booking are checked out as well.',
    emails: {
      confirmation: {
        subject: 'Booking at {gym_name} confirmed',
        confirmed: 'Booking confirmed',
        activity_at: '{activity} at {gym_name}',
        confirmation: 'Your booking for {area_name} at {gym_name} has been confirmed.',
        checkin_time: 'Check-in time',
        booking_number: 'Booking number',
        manage_online: 'You can manage your bookings online through TopLogger:',
        manage_booking: 'Manage booking',
        thanks: 'Thanks!',
      },
      no_show: {
        subject: 'Missed booking',
        you_are_marked: 'Your booking at {gym_name} has been marked as a no-show.',
        time_was: 'The check-in time was on {date} between {time_start} and {time_end}.',
        cancel: 'You can cancel your reservations at any time by logging into your TopLogger account.',
        questions: 'If you have any questions please contact the center directly.',
      },
      checkoutReminder: {
        subject: 'Checkout reminder',
        reminder: 'This is a kind reminder from {gym_name} to check out at the desk.',
        time_is: 'Your checkout time is on {date} at {time_end}.',
      },
    },
  },
  help: {
    title: 'Help | TopLogger',
    description:
      'How it works. The rules for climbing, topping and logging your ascents in the TopLogger indoor climbing app.',
    faq: {
      header: 'FAQ',
      subhead: 'Frequently asked questions',
      example: 'Example',
      qGradeCalc: 'How is my grade calculated?',
      aGradeCalc:
        'Your grade is calculated using the average of your 10 highest rated ascents during the past two months.',
      aGradeCalcEx1:
        "Your ten highest rated ascents include five 6as and five 6a+s. This means your grade is exactly halfway between 6a and 6a+. You are 50% on your way to become a 6a+ climber, so your current grade will be '6a with 50%'.",
      aGradeCalcEx2:
        "Your ten highest rated ascents include one 6a and nine 6a+s. This means your grade is almost 6a+: you just need on more. You are 90% on your way to become a 6a+ climber, so your current grade will be '6a with 90%'.",
      showPoints: 'Show points system',
      qTopFlash: "What's the difference between a top and a flash?",
      qTopFlashRp: 'What is RP, FL and OS?',
      qCareTopFl: 'Why should I care about topping or flashing?',
      aCareTopFl:
        "A flash gives you more points: Flashing a boulder adds a '+' to the boulders grade in your grade calculation.",
      aCareTopFlEx1: 'You flashed a 6a. In your grade calculation this ascent counts as if you have topped a 6a+.',
      aCareTopFlEx2: 'You flashed a 6c+. In your grade calculation this ascent counts as if you have topped a 7a.',
      qCareTopFlRp: 'Why should I care about redpointing, flash or onsighting?',
      aCareTopFlRp:
        "Flashing and Onsighting give you more points. Flashing a route adds a '+' to the route grade in your grade calculation. Onsighting a route adds two '+'s.",
      aCareTopFlRpEx1: 'You flashed a 6a. In your grade calculation this ascent counts as if you have topped a 6a+.',
      aCareTopFlRpEx2: 'You onsighted a 6a. In your grade calculation this ascent counts as if you have topped a 6b.',
      aCareTopFlRpEx3: 'You flashed a 6c+. In your grade calculation this ascent counts as if you have topped a 7a.',
      aCareTopFlRpEx4: 'You onsighted a 6c+. In your grade calculation this ascent counts as if you have topped a 7a+.',
      qScoreTime: 'How long will my ascents last in my rankings?',
      aScoreTimeGrade:
        "Your grade ranking is based on your 10 hardest ascents in the past two months. It doesn't matter if the climb has been removed.",
      aScoreTimeCount:
        'The count ranking is based on all the climbs that are currently at the gym. If a climb is removed, everyone who has topped it will lose one ascent in the counts ranking.',
      qGradeStability: 'What is that colored dot?',
    },
    guide: {
      header: 'Beginners guide to climbing',
      subhead: 'The rules of the game',
      intro:
        "Bouldering is about getting to the top using a single color. But there are some rules. Here's a short explanation about the rules of the game:",
      starting: 'Starting',
      startingExpl1:
        "Start with your hands on the holds with the ticks. Put your feet anywhere but the mat and hang steady before you start climbing: this means that you won't be able to start jumping unless stated in the description of the boulder.",
      startingExpl2:
        'There might be some other sidenotes, like sitstart, meaning you have to start from a sitting position.',
      climbing: 'Climbing',
      climbingExpl: "Climbing is 'simple': you have to get to the top through the same-colored holds.",
      topping: 'Topping',
      toppingExpl: "You 'top' a boulder when you touch the final hold with both hands for three seconds.",
      logging: 'Logging',
      loggingExpl: 'Logging your sent routes can be done in three ways:',
      loggingOs: 'Onsight (OS) <small>first try, no info</small>',
      loggingOsExpl: 'If you sent a route on the first go without information from others, it is called onsighting',
      loggingFl: 'Flash (FL) <small>first try, with info</small>',
      loggingFlExpl:
        "If you sent the route on the first go, but you had information about how it's done (or not done), you have 'flashed' the route. This is the case if you saw someone else try the route before, or if someone told you how to do it.",
      loggingRp: 'Redpoint (RP) <small>the rest</small>',
      loggingRpExpl: 'If you topped the boulder, but not on the first go, you have redpointed it.',
    },
  },
  contact: {
    title: 'Contact | TopLogger',
    description: 'Get in touch and get TopLogger available in your gym.',
    header: 'Contact',
    subhead: "Let's get in touch",
    wantInGym: 'Would you like to set up TopLogger in your gym?',
    sendMail: 'Just send us an email:',
  },
  feedback: {
    title: 'Feedback | TopLogger',
    description: 'Give your opinion about TopLogger.',
    header: 'Send feedback',
    subhead: 'Suggest changes or improvements',
    forGym: 'Feedback for {gymName}',
    forApp: 'Feedback for the app',
    explanation: 'Suggest changes and improvements for the app or report bugs.',
    explanationGym: "Tell {gymName} what could be improved, what's broken, or simply use it as a suggestion box.",
    gymFeedbackMsg: 'For missing climbs or wrong data, please contact your gym.',
    yourFeedback: 'Your feedback',
    yourFeedbackSub: 'Get replies',
    message: 'Message',
    feedbackGym: 'Gym feedback',
    feedbackApp: 'App feedback',
    reply: 'Reply',
    retract: 'Retract',
    inputLabel: 'Write your feedback (anonymous)',
    successMsg: 'Great! Thanks for your feedback!',
  },
  terms: {
    title: 'Terms of Service | TopLogger',
    description: 'Terms of service and privacy policy for TopLogger',
  },
  components: {
    gradesChart: {
      header: 'Grade conversion chart',
      explanation: 'Points relative to different grade systems',
    },
  },
  $vuetify, // Do not translate me please :)
}
